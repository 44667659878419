import React, { useRef } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import "slick-carousel/slick/slick.css";

const gradientBackground = css`
  background-color: #1a1a1a;
background-image:
  radial-gradient(circle at 60% 70%, rgba(15, 35, 55, 0.95) 0%, rgba(25, 45, 65, 0.90) 50%, rgba(10, 30, 50, 0) 100%, transparent 100%),
  radial-gradient(circle at 50% 50%, rgba(20, 40, 60, 0.95) 0%, transparent 100%);
`;

const StyledSliderContainer = styled.div`
  ${tw`text-white -m-12`}
  ${gradientBackground}
`;

const TestimonialsSlider = styled(Slider)`
  ${tw`mx-auto md:mx-24 max-w-full`}
  .slick-slide {
    ${tw`p-16`}
  }
`;

const Testimonial = styled.div`
  ${tw`bg-white rounded-lg shadow-xl overflow-hidden`}
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const TestimonialContent = styled.div`
  ${tw`p-6 sm:p-8 flex flex-col h-full`}
`;

const Quote = styled.blockquote`
  ${tw`font-medium text-gray-800 text-base mb-6 flex-grow`}
`;

const Author = styled.div`
  ${tw`mt-4`}
`;

const AuthorName = styled.span`
  ${tw`block text-lg font-semibold text-gray-900`}
`;

const AuthorPosition = styled.span`
  ${tw`block text-sm text-gray-600`}
`;

const ControlsContainer = styled.div`
  ${tw`flex justify-center space-x-4 pb-16 z-50`}
`;

const ControlButton = styled.button`
  ${tw`p-2 rounded-full bg-white text-gray-800 focus:outline-none`}
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #f3f4f6;
  }
  .icon {
    ${tw`w-6 h-6`}
  }
`;

const isMobile = window.innerWidth <= 768;

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: isMobile ? 1 : 3,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};



const Testimonials = ({
  translations
}) => {
  const sliderRef = useRef(null);
  const testimonials = !isMobile ? translations.testimonialsData : translations.testimonialsData.slice(-5);

  const navigateSlide = (direction) => () => {
    if (sliderRef.current) {
      direction === 'prev' ? sliderRef.current.slickPrev() : sliderRef.current.slickNext();
    }
  };

  return (
    <StyledSliderContainer>
      <TestimonialsSlider {...settings} arrows={false} ref={sliderRef}>
        {testimonials.map((testimonial, index) => (
          <Testimonial key={index}>
            <TestimonialContent>
              <Quote>{testimonial.quote}</Quote>
              <Author>
                <AuthorName>{testimonial.customerName}</AuthorName>
                <AuthorPosition>{testimonial.position || 'Community member'}</AuthorPosition>
              </Author>
            </TestimonialContent>
          </Testimonial>
        ))}
      </TestimonialsSlider>
      <ControlsContainer>
        <ControlButton onClick={navigateSlide('prev')}>
          <ArrowLeftIcon className="icon" />
        </ControlButton>
        <ControlButton onClick={navigateSlide('next')}>
          <ArrowRightIcon className="icon" />
        </ControlButton>
      </ControlsContainer>
    </StyledSliderContainer>
  );
};

export default Testimonials;